.container {
    display: flex;
    flex-direction: row; /* Keep the layout side by side */
    height: 100vh; /* Full screen height */
    width: 100vw;
    overflow: hidden; /* Prevent scrolling */
  }
  
  .left-side {
    flex: 1; /* Left side takes half the screen */
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    padding: 20px; /* Add padding for spacing */
    background-color: #ffffff; /* Background color */
  }
  
  .right-side {
    flex: 1; /* Right side takes half the screen */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e3cdcd; /* Background color */
  }
  
  .right-side img {
    max-width: 100%; /* Reduce maximum width */
    height: auto; /* Maintain aspect ratio */
    margin-top: 20px; /* Add some space on top */
  }
  
  .contact-item {
    display: flex;
    align-items: center; /* Align items vertically */
    justify-content: center;
    margin-right: 100px; /* Sol taraftaki ikon ve yazıların to contact us altına gelmesi için yapıldı. */
    margin-bottom: 20px; /* Space between items */
    width: 100%; /* Ensure contact items take full width */
  }
  
  .contact-item img {
    width: 50px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
    margin-right: 15px; /* Space between image and text */
  }
  
  .contact-info {
    display: flex;
    flex-direction: column; /* Stack text vertically */
  }
  
  .header-size {
    font-family: "Hammersmith One", sans-serif;
    font-size: 50px;
    font-weight: normal;
    text-align: center;
  }
  
  /* Mobile view */
  @media (max-width: 768px) {
    .container {
      flex-direction: column; /* Stack vertically on mobile */
      height: auto; /* Auto height */
    }
  
    .left-side,
    .right-side {
      flex: none; /* Disable flex */
      width: 100%; /* Full width */
    }
  
    .left-side {
      align-items: flex-start; /* Align items to the left */
      padding: 10px; /* Reduced padding for mobile */
    }
  
    .header-size {
      font-size: 40px; /* Adjust font size for mobile */
      text-align: center; /* Align text to the left */
      margin-bottom: .7rem; /* Remove default margin */
      padding-left: 2.5rem; /* Remove default padding */
     
    }
    .right-side {
      display: flex; /* Center image */
      justify-content: center;
      align-items: flex-start; /* Align to the top */
    }
  
    .contact-item {
      justify-content: flex-start; /* Align items to the left */
      text-align: left; /* Left-align text on mobile */
      margin-left: 10px; /* Reset margin-left for mobile */
      margin-right: 0; /* Reset margin-right for mobile */
      width: auto; /* Allow width to adjust automatically */
    }
  
    .contact-info {
      align-items: flex-start; /* Align text to the left */
    }
  
    .contact-item img {
      margin-right: 10px; /* Add space between image and text */
      margin-bottom: 0; /* Remove bottom margin on mobile */
    }
  }
 
  