.text-grid {
    display: flex;
    justify-content: center; /* Center items in larger view */
    flex-wrap: wrap; /* Allow wrapping */
    gap: 80px; /* Space between items */
  }
  
  .text-item {
    margin-top: 10px;
    margin-right: 10px;
  }
  
  .header-item {
    font-family: "Hammersmith One", sans-serif;
    font-size: 54px;
    font-weight: normal;
    text-align: center;
    margin-top: 50px;
  }
  
  .image-grid {
    display: flex;
    justify-content: center; /* Center items in larger view */
    flex-wrap: wrap; /* Allow wrapping */
    gap: 105px; /* Space between items */
  }
  
  .image-item {
    display: flex;
    flex-direction: column; /* Stack image and text vertically */
    align-items: center; /* Center items horizontally */
    text-align: center; /* Center text */
  }
  
  .image-item img {
    max-width: 100%; /* Responsive images */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Optional: rounded corners */
  }
  
  @media (max-width: 768px) {
    .image-grid {
      flex-direction: column; /* Stack images vertically */
      align-items: center; /* Center items horizontally */
      gap: 0px; /* Adjust space between items */
    }
  
    .image-item {
      margin-bottom: 0px; /* Space between stacked items */
      width: 100%; /* Full width for better centering */
      max-width: 300px; /* Limit the width for larger screens */
      display: flex; /* Ensure the flex behavior remains */
      justify-content: center;
      text-align: center; /* Center items in the item */
    }
    .header-item {
      font-size: 30px;
    }
  }