

.announcements_general_container{
    
    display:block;
    text-align: center;
    justify-content: end;
    align-items: end;
    width: 100%;
    margin-top: 1rem;

}
.announcements_head{
    margin-top: .5rem;
    font-size: 5rem;
    font-weight: bold;
    font-family: "Open Sans";
}
.announcements_container{
    padding: 5rem;
    overflow: hidden;
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-top: 2rem;
    gap:3rem;
    
}
.col{
    display: block;
    align-items: center;
    justify-content: end;
    text-align: center; 
}   
.col:hover{
    scale: 1.1;
}

.image{
    width: 80%;
}


.col_head{
    padding-top: 1.2rem;
    width: 80%;
    padding-left: 5rem;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: "Clean Sans";
}

.col_prg{
    padding-top: .5rem;
    width: 80%;
    padding-left: 5rem;
    font-size: 1rem;
    font-family: "Clean Sans";
}
.readMore{
    color: #ffb923;
    font-weight: 600;
    border: none;
    background:none;
    text-decoration:underline;
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .announcements_head{
        margin-top: .5rem;
        font-size: 3rem;
        font-weight: bold;
        font-family: "Open Sans";
    }
    
    .announcements_container{
        padding: 5rem;
        overflow: hidden;
        display: flex;
        flex: 1;
        justify-content: space-between;
        margin-top: 1.3rem;
        gap:1.5rem;
        
    }

    .col_head{
        padding-top: 1.2rem;
        width: 80%;
        padding-left: 3rem;
        font-size: 1rem;
        font-weight: bold;
        font-family: "Clean Sans";
    }
    
    .col_prg{
        padding-top: .5rem;
        width: 80%;
        padding-left: 3rem;
        font-size: .5rem;
        font-family: "Clean Sans";
    }
  }

@media (min-width: 768px) and (max-width: 1024px)  {
  
    .announcements_head{
        margin-top: .5rem;
        font-size: 3rem;
        font-weight: bold;
        font-family: "Open Sans";
    }
    
    .announcements_container{
        padding: 4rem;
        overflow: hidden;
        display: flex;
        flex: 1;
        justify-content: space-between;
        margin-top: 1.3rem;
        gap:1.5rem;
        
    }

    .col_head{
        padding-top: 1.1rem;
        width: 80%;
        padding-left: 2rem;
        font-size: 1.2rem;
        font-weight: bold;
        font-family: "Clean Sans";
    }
    
    .col_prg{
        padding-top: .5rem;
        width: 80%;
        padding-left: 2rem;
        font-size: .7rem;
        font-family: "Clean Sans";
    }
    
  }
  @media (min-width: 481px) and (max-width: 767px) {
    .col{
        margin-bottom: 2rem;
    }
  
    .announcements_head{
        margin-top: .5rem;
        font-size: 2rem;
        font-weight: bold;
        font-family: "Open Sans";
    }
    
    .announcements_container{
        padding: 4rem;
        overflow: hidden;
        display: block;
        flex: 1;
        justify-content: space-between;
        margin-top: .5rem;
        gap:2rem;
        
    }

    .col_head{
        padding-top: 1.1rem;
        width: 80%;
        padding-left: 5rem;
        font-size: 2rem;
        font-weight: bold;
        font-family: "Clean Sans";
    }
    
    .col_prg{
        padding-top: .5rem;
        width: 80%;
        padding-left: 5.5rem;
        font-size: 1rem;
        font-family: "Clean Sans";
    }
    
  }

  @media (min-width: 320px) and (max-width: 480px) {
  
    .col{
        margin-bottom: 2rem;
    }
  
    .announcements_head{
        margin-top: .5rem;
        font-size: 1.5rem;
        font-weight: bold;
        font-family: "Open Sans";
    }
    
    .announcements_container{
        padding: 4rem;
        overflow: hidden;
        display: block;
        flex: 1;
        justify-content: space-between;
        margin-top: .5rem;
        gap:2rem;
        
    }

    .col_head{
        padding-top: 1.1rem;
        width: 80%;
        padding-left: 3rem;
        font-size: 1rem;
        font-weight: bold;
        font-family: "Clean Sans";
    }
    
    .col_prg{
        padding-top: .5rem;
        width: 80%;
        padding-left: 3rem;
        font-size: 1rem;
        font-family: "Clean Sans";
    }
  }